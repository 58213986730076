import { http } from '@/api';

// 获取我的心愿单列表
export function getWishList(params) {
  return http.get('/v1/makewish/getuserwishingorders', params);
}

// 挂单配置藏品
export function getwishconfig(params) {
  return http.get('/v1/makewish/getwishconfig', params);
}

//许愿赠送、求赠
export function makewish(params) {
  return http.post('/v1/makewish/makewish', params);
}

//修改许愿赠送、求赠
export function setmakewish(params) {
  return http.post('/v1/makewish/setmakewish', params);
}


// 撤回许愿赠送、求赠
export function cancelwish(params) {
  return http.post('/v1/makewish/cancelwish', params);
}

// 校验许愿赠送、求赠价格
export function compareprice(params) {
  return http.post('/v1/makewish/compareprice', params);
}

// 获取许愿赠送、求赠藏品
export function getcollections(params) {
  return http.get('/v1/makewish/getcollections', params);
}

// 获取我的心愿单达成列表
export function getusersuccesswishingorders(params) {
  return http.get('/v1/makewish/getusersuccesswishingorders', params);
}

// 分享愿望落地页
export function sharedetail(params) {
  return http.get('/v1/makewish/sharedetail', params);
}

// 心愿单确认接口
export function confirmwish(params) {
  return http.post('/v1/makewish/confirmwish', params);
}

// 获取一画许愿藏品筛选接口
export function getmakewishcollection(params) {
  return http.get('/v2/makewish/getmakewishcollection', params);
}

// 许愿赠出、获赠(一画)
export function yihuamakewish(params) {
  return http.post('/v2/makewish/yihuamakewish', params);
}

// 修改许愿赠出、获赠(一画)
export function setyihuamakewish(params) {
  return http.post('/v2/makewish/setyihuamakewish', params);
}

// 撤回许愿赠出、获赠(一画)
export function yihuacancelwish(params) {
  return http.post('/v2/makewish/yihuacancelwish', params);
}












