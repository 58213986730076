<template>
  <!-- "wish_type": 1,1 许愿获赠，2 许愿赠出 -->
  <div id="wishSharePage">
    <div class="shareContainer">
      <div class="info">
        <div class="itemImg">
          <img class="imgThumb" :src="shareInfo.image_icon" alt="" srcset="" />
          <img
            v-if="levelsChange(shareInfo.levels)"
            class="levelIcon"
            :src="levelsChange(shareInfo.levels)"
            alt=""
          />
        </div>

        <div>
          {{ shareInfo.config_name }}
          <span
            class="nameNumIcon"
            v-if="shareInfo.wish_num - shareInfo.success_num && shareInfo.status == 1"
          >
            x
          </span>
          <span
            class="nameNum"
            v-if="shareInfo.wish_num - shareInfo.success_num && shareInfo.status == 1"
            >{{ shareInfo.wish_num - shareInfo.success_num }}</span
          >
        </div>
        <div class="name">看漫数藏 - 《{{ shareInfo.display_name }}》</div>
      </div>
      <div class="shareDesc">
        <div class="text">
          <div v-if="shareInfo.status == 1">
            <div v-if="shareInfo.wish_type == 1">
              您的好友{{ shareInfo.user_name }}想收藏{{
                shareInfo.wish_num - shareInfo.success_num
              }}份《{{ shareInfo.display_name }}》{{
                shareInfo.config_name
              }}，如您助TA达成愿望，将获得
              <div style="color: #3cdbe8">￥{{ shareInfo.total_price }}的感谢费</div>
            </div>
            <div v-if="shareInfo.wish_type == 2">
              您的好友{{ shareInfo.user_name }}想送你{{
                shareInfo.wish_num - shareInfo.success_num
              }}份《{{ shareInfo.display_name }}》{{ shareInfo.config_name }}，支付
              <span style="color: #3cdbe8">￥{{ shareInfo.total_price }}的感谢费</span
              >立即签收该藏品
            </div>
          </div>
          <div v-else>
            您的好友{{ shareInfo.user_name }}想{{ shareInfo.wish_type == 1 ? '收藏' : '送' }}你 《{{
              shareInfo.display_name
            }}》{{ shareInfo.config_name }}的愿望已达成或已撤回
          </div>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div v-if="!isLogin" class="footerBtn" @click="showWishConfirm">
      {{ shareInfo.wish_type == 1 ? '达成愿望' : '签收礼物' }}
    </div>

    <div
      v-if="shareInfo.status == 1 && diffHours() > 72"
      class="footerBtn"
      @click="showWishConfirm"
    >
      {{ shareInfo.wish_type == 1 ? '达成愿望' : '签收礼物' }}
    </div>

    <div v-if="shareInfo.status == 1 && diffHours() < 72" class="footerBtn" @click="countTime">
      {{ shareInfo.wish_type == 1 ? '达成愿望' : '签收礼物' }}
    </div>

    <div v-if="shareInfo.status !== 1" class="footerBtn noBtn" @click="toastMsg">
      {{ shareInfo.wish_type == 1 ? '达成愿望' : '签收礼物' }}
    </div>

    <!-- 确认实现愿望弹窗 -->
    <wishConfirm ref="wishConfirm" @success="getDetail" />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref, inject } from 'vue';
import wishConfirm from '@/components/wishConfirm.vue';
import { localStorageUtil, nftUtils } from '@/utils';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { sharedetail } from '@/service/wish';
import moment from 'moment';

export default {
  name: 'wishSharePage',
  components: {
    wishConfirm
  },
  setup() {
    const toastFn = inject('$toast');
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const wishConfirm = ref('wishConfirm');
    const state = reactive({
      server_time: null,
      created_time: null,
      isLogin: nftUtils.isLogin(),
      isVerify: false,
      shareInfo: {},

      share_id: route.query.shareId || null
      // share_id: '19c447f3074d1f31aa571d36c3e998c7'
    });

    const getDetail = () => {
      sharedetail({ share_id: state.share_id }).then(res => {
        console.log(res);
        state.shareInfo = res.data;
      });
      wishConfirm.value.setInfo(state.shareInfo);
    };

    const showWishConfirm = () => {
      if (!state.isLogin) {
        router.push({ path: '/login' });
        return;
      }
      if (!state.isVerify) {
        router.push({ path: `/authentication` });
        return;
      }
      wishConfirm.value.showSheet(state.shareInfo);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    onMounted(async () => {
      localStorageUtil.del('shareId');
      getDetail();
      if (state.isLogin) {
        await store.dispatch('refreshLoginInfo');
        state.isVerify = store.state.loginInfo ? !!store.state.loginInfo.id_verify : false;

        state.created_time = store.state.loginInfo.created_time;
        state.server_time = Number(localStorageUtil.get('server_time'));
        console.log(
          'state.server_time',
          moment(state.created_time)
            .add(+3, 'd')
            .format('YYYY-MM-DD HH:mm:ss')
        );
      }
    });

    // 对比时间是否超过多少小时
    const diffHours = () => {
      if (state.shareInfo.wish_type == 1) {
        return moment(state.server_time).diff(
          moment(Number(moment(state.created_time).format('x'))),
          'hours'
        );
      } else {
        // 随便设置一个数额当wish_type==2时不做限制
        return 1000;
      }
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(
        moment(moment(state.created_time).add(3, 'd').format('YYYY-MM-DD HH:mm:ss'))
      ).diff(state.server_time, 'millisecond');
    };

    const countTime = () => {
      toastFn({ message: '新用户注册后3天后才可转赠藏品', duration: 3000 });
    };

    const toastMsg = () => {
      toastFn({ message: '该愿望已达成或已撤回', duration: 3000 });
    };

    return {
      ...toRefs(state),
      levelsChange,
      showWishConfirm,
      getDetail,
      diffHours,
      diffTime,
      countTime,
      toastMsg,
      wishConfirm
    };
  }
};
</script>

<style lang="scss" scoped>
.shareContainer {
  // height: 100%;
  padding-bottom: 180px;
  background-image: url('https://activity-resource.321mh.com/nft/notice/xuyuan/red_bg_2.png');
  background-size: contain;

  .info {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
  }

  .itemImg {
    font-size: 0;
    position: relative;
    padding-top: 30px;

    .imgThumb {
      width: 205px;
    }

    .levelIcon {
      position: absolute;
      width: 80px;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .name {
    line-height: 30px;
  }

  .nameNumIcon {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }

  .nameNum {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
  }

  .shareDesc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 344px;
    height: 112px;
    margin: 0 auto;
    margin-top: 10px;
    background-image: url('https://activity-resource.321mh.com/nft/notice/xuyuan/white_bg.png');
    background-size: contain;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    .text {
      text-align: center;
      padding: 0 25px;
    }
  }
}

.footerBtn {
  position: fixed;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  width: 252px;
  height: 44px;
  background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
  text-align: center;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  font-weight: 500;
  color: #1c172a;
  line-height: 44px;
}
.noBtn {
  opacity: 0.6;
}
</style>
