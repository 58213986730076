<template>
  <div @click="closeSel">
    <van-action-sheet
      v-model:show="show"
      :round="false"
      style="background-color: #1c172a"
      class="wishConfirm"
    >
      <div class="header">
        <img src="../imgs/comm/pic_tc_zs.png" class="img-zs" />
        <van-icon :name="icon_tc_gb" size="0.427rem" class="icon_tc_gb" @click="show = false" />
        <span class="type">确认愿望信息</span>
      </div>
      <div class="block"></div>

      <div class="form">
        <div class="formItem">
          <div class="label">
            <img class="imgIcon" :src="shareInfo.image_thumb" alt="" />
          </div>
          <div class="nameContentNew">
            <div class="">
              <span>{{ shareInfo.config_name }}</span>
            </div>
            <div class="displayBt">
              <span>看漫数藏 - 《{{ shareInfo.display_name }}》</span>
            </div>
          </div>
        </div>
        <div class="formItem">
          <div class="label">{{ shareInfo.wish_type == 1 ? '赠出数量' : '获赠数量' }}</div>
          <div class="formContent">
            <van-stepper
              v-model="wish_num"
              :integer="true"
              :max="
                shareInfo.wish_type == 2
                  ? shareInfo.wish_num - shareInfo.success_num
                  : shareInfo.wish_num - shareInfo.success_num > has_num
                  ? has_num
                  : shareInfo.wish_num - shareInfo.success_num
              "
              :min="shareInfo.wish_type == 1 && has_num == 0 ? 0 : 1"
              class="stepper"
              input-width="60px"
            />
          </div>
        </div>
        <div
          class="formItem"
          v-if="shareInfo.wish_type == 2 || (shareInfo.wish_type == 1 && has_num !== 0)"
        >
          <div class="label">感谢费</div>
          <div class="formContent priceItem">
            <span>{{ shareInfo.unit_price }}元/份</span>
            <span class="sum">合计：{{ wish_num * shareInfo.unit_price }}元</span>
          </div>
        </div>
      </div>

      <div class="redTips" v-if="shareInfo.wish_type == 1 && has_num == 0">
        该藏品您可赠出的份数为0
      </div>
      <div v-if="shareInfo.wish_type == 1 && has_num == 0" class="formSubmit unSubmit">确定</div>
      <div v-else class="formSubmit" @click="confirmWish">确定</div>

      <div class="tips" v-if="shareInfo.wish_type == 1">
        *若愿望达成，您的钱包账户将收到以上感谢费（平台服务费5%, 推广期间免费）
      </div>
      <div class="agreeTips" v-if="shareInfo.wish_type == 2">
        <span @click="agreeFlag = !agreeFlag">
          <van-icon class="icon" size="0.427rem" :name="agreeFlag ? coll_choose : coll_no_choose" />

          <span class="text">愿望达成时，允许从钱包中自动扣除感谢费</span>
        </span>
      </div>
    </van-action-sheet>

    <popup
      v-model:show.sync="sendPopShow"
      title="赠送成功"
      desc="愿望赠送成功，是否前往“钱包”查看收入是否到账（可能延迟到账）"
      :btncon="{ cancel: '取消', success: '查看钱包' }"
      @confirm="toQb"
      @cancel="sendPopShow = false"
    />

    <popup
      v-model:show.sync="getPopShow"
      title="求赠成功"
      desc="愿望求赠成功，是否前往“我的藏品”查看藏品"
      :btncon="{ cancel: '取消', success: '查看藏品' }"
      @confirm="gocollections"
      @cancel="getPopShow = false"
    />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { confirmwish, getcollections } from '@/service/wish';
import { sandpayCloud } from '@/service/user';
import popup from '@/components/popup/index.vue';
import { Toast } from 'vant';
import { localStorageUtil } from '../utils';

export default {
  emits: ['success'],
  props: {},
  components: {
    popup
  },

  setup(props, ctx) {
    const router = useRouter();
    const route = useRoute();
    const toastFn = inject('$toast');

    const state = reactive({
      show: false,
      shareInfo: {},
      wish_num: 1,
      share_wish_id: null,
      has_num: 0,
      sendPopShow: false,
      getPopShow: false,
      agreeFlag: false,
      shareId: route.query.shareId
    });
    onMounted(async () => {});

    const showSheet = row => {
      state.show = true;
      state.agreeFlag = false;
      state.shareInfo = row;
      if (row.wish_type == 1) {
        getHasNum();
      }
    };

    const setInfo = row => {
      state.shareInfo = row;
      if (row.wish_type == 1) {
        getHasNum();
      }
    };

    const getHasNum = () => {
      getcollections({
        wish_type: 2,
        wish_id: state.shareInfo.wish_id
      }).then(res => {
        let collectList = res.data[state.shareInfo.display_name];
        for (let i in collectList) {
          if (collectList[i].config_name == state.shareInfo.config_name) {
            state.has_num = collectList[i].has_num;
          }
        }
      });
    };

    // 确认实现愿望
    const confirmWish = () => {
      if (state.shareInfo.wish_type == 2 && !state.agreeFlag) {
        toastFn({ message: '需同意自动扣除感谢费', duration: 3000 });
        return;
      }
      Toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true,
        duration: 3000
      });
      confirmwish({
        share_wish_id: state.shareInfo.wish_id,
        wish_num: state.wish_num
      }).then(res => {
        Toast.clear();
        if (res.status == 0) {
          if (state.shareInfo.wish_type == 1) {
            state.sendPopShow = true;
          } else {
            state.getPopShow = true;
          }
          state.show = false;
          ctx.emit('success');
        } else if (res.status == 421 || res.status == 1) {
          // 没有开通钱包账号要跳去开通
          //
          // toastFn({ message: res.message, duration: 3000 });
          if (res.status == 421) {
            localStorageUtil.set('shareId', state.shareId);
          }
          sandpayCloud({
            page_type: res.status == 1 ? 2 : null
          }).then(resIn => {
            console.log(resIn);
            if (resIn.status == 0) {
              window.location.href = resIn.data.url;
            }
          });
        } else {
          toastFn({ message: res.message, duration: 3000 });
        }
      });
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    const gocollections = () => {
      router.push({ path: '/collections' });
    };
    const toQb = () => {
      sandpayCloud({}).then(res => {
        console.log(res);
        if (res.status == 0) {
          window.location.href = res.data.url;
        }
      });
      // router.push({ path: '/myHb' });
    };

    return {
      ...toRefs(state),
      showSheet,
      levelsChange,
      confirmWish,
      gocollections,
      toQb,
      setInfo,
      icon_tc_gb: require('@/imgs/icon/icon_tc_gb.png'),
      coll_no_choose: require('@/imgs/icon/coll_no_choose.png'),
      coll_choose: require('@/imgs/icon/coll_choose.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';

.wishConfirm {
  overflow: hidden;
  .countDown {
    display: inline-block;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: $bc;
    padding-top: 3px;

    .img-zs {
      height: 16px;
    }
    .icon_tc_gb {
      margin-left: 300px;
      width: 16px;
      height: 16px;
    }
    .type {
      // line-height: 16px;
      font-size: 18px;
    }
    span {
      font-size: 20px;
      color: $fc;
      margin-top: -20px;
    }
  }

  .block {
    height: 60px;
    background-color: $bc;
  }

  .stepper {
    --van-stepper-background-color: #494359;
    --van-stepper-button-disabled-color: rgba(73, 67, 89, 0.5);
    --van-stepper-button-disabled-icon-color: rgba(255, 255, 255, 0.2);
    --van-stepper-button-icon-color: #fff;
    --van-stepper-input-text-color: #fff;
    --van-stepper-input-disabled-background-color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    // height: 26px;
    overflow: hidden;
    font-size: 0px;
  }

  .form {
    padding: 0 20px;
    .formItem {
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 25px;

      .label {
        width: 66px;
        text-align: left;

        .imgIcon {
          width: 56px;
          height: 56px;
        }
      }

      .formContent {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 8px;
      }

      .priceItem {
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          font-weight: 300;
          color: #ffffff;
        }
        .sum {
          margin-left: 15px;
        }
      }
      .nameContentNew {
        margin: 4px 16px;

        .displayBt {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.7);
          margin-top: 8px;
        }
      }

      .nameContent {
        :first-child {
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }

        :last-child {
          margin-left: 12px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }

  .redTips {
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
    color: #ea4444;
    line-height: 22px;
    margin-bottom: 35px;
  }

  .formSubmit {
    width: 335px;
    height: 44px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #1c172a;
    line-height: 44px;
    margin: 0 auto;
  }

  .unSubmit {
    opacity: 0.6;
  }

  .tips {
    padding: 14px 20px 24px 20px;
    font-size: 12px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
    line-height: 18px;
  }

  .agreeTips {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 20px 24px 20px;
    font-size: 12px;
    font-weight: 300;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    > span {
      display: inline-flex;
    }
    .text {
      margin-left: 6px;
    }
  }
}
</style>
<style>
.vantPop {
  background: transparent !important;
}
</style>
